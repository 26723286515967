











import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { TrackData, UserData } from "./UserProfile.vue";

@Component({
	components:{}
})
export default class CurrentTrack extends Vue {

	@Prop()
	public data:UserData;

	@Prop()
	public track:TrackData;
	
	private player:any;
	private interval:any;
	private startTime:number;
	private endTime:number;

	public get lyrics():string {
		return this.track?.lyrics?.trim().replace(/</gi, "&lgt;").replace(/\*\*(.*?)\*\*/gi, "<strong>$1</strong>");
	}

	public mounted():void {
		//@ts-ignore
		this.player = new YT.Player('ytplayer', {
			width: '480',
			height: '270',
			// videoId: id,
			playerVars: { 'autoplay': 1 },
			events: {
				'onReady': (e)=> this.onPlayerReady(e),
				'onStateChange': (e)=> this.onPlayerStateChange(e)
			}
		});

		this.populate();

		this.interval = setInterval(_=> {
			if(!this.player || !this.player.getCurrentTime) return;
			if(this.player.getCurrentTime() > this.endTime) {
				this.player.seekTo(this.startTime);
			}
		},500);
	}

	@Watch("track")
	public populate():void {
		if(!this.track) {
			if(this.player.pauseVideo) this.player.pauseVideo();
			return;
		}
		this.startTime = new Date("01/01/1970 01:"+this.track.timecodeStart).getTime()/1000;
		this.endTime = new Date("01/01/1970 01:"+this.track.timecodeEnd).getTime()/1000;
		if(this.player.loadVideoById) {
			this.onPlayerReady();
		}
	}

	public beforeDestroy():void {
		this.player.destroy();
		clearInterval(this.interval);
	}

	public onPlayerReady(e?):void {
		if(!this.track) return;
		
		let id = this.track.video.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/)[1];
		this.player.loadVideoById(id, this.startTime, this.endTime);
		// this.player.playVideo();
        // this.player.seekTo(this.startTime);
	}

	public onPlayerStateChange(e):void {
		// console.log("ON STATE CHANGE");
		// console.log(e);
	}

}
