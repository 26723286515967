










import SockController from "@/sock/SockController";
import { Component, Vue } from "vue-property-decorator";
import Alert from "./views/AlertView.vue";
import Confirm from "./views/Confirm.vue";

@Component({
	components:{
		Alert,
		Confirm,
	}
})
export default class App extends Vue {

	public get connected():boolean {
		return SockController.instance.connected;
	}

	public async mounted():Promise<void> {
		
	}

	public beforeDestroy():void {
	}

}
