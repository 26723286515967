import Api from '@/utils/Api';
import Config from '@/utils/Config';
import Vue from 'vue';
import Vuex from 'vuex';
import Store from './Store';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		OAuthToken: "",//Stores the OAUth user access token
		userLogin: "",//Stores the current user's login
		ready:false,
		alert: null,
		confirm:{
			title:null,
			description:null,
			confirmCallback:null,
			cancelCallback:null,
			yesLabel:null,
			noLabel:null,
		},
	},
	mutations: {
		confirm(state, payload) { state.confirm = payload; },

		alert(state, payload) { state.alert = payload; },

		async setOAuthToken(state, token) {
			if(!token) {
				state.OAuthToken = null;
				Store.remove("OAuthToken");
			}else{
				let json = await Api.get("user/me", {token});
				if(json.success && json.user.id) {
					state.userLogin = json.user.username;
					state.OAuthToken = token;
					Store.set("OAuthToken", token);
				}else{
					state.OAuthToken = null;
					Store.remove("OAuthToken");
				}
			}
		},
	},
	actions: {
		async startApp({ state, commit, dispatch }, payload) {
			let token = Store.get("OAuthToken");
			//Check if token is valid and has all needed scopes
			if(token) {
				let tokenValid = true;
				try {
					let json = await Api.get("user/me", {token});
					if(json.success && json.user.id) {
						state.userLogin = json.user.username;
						state.OAuthToken = token;
					}else{
						tokenValid = false;
					}
				}catch(error) {
					tokenValid = false;
				}
				if(!tokenValid) {
					commit("setOAuthToken", null);
				}else{
					commit("setOAuthToken", token);
				}
			}else{
				commit("setOAuthToken", null);
			}
			state.ready = true;
			return true;
		},

		confirm({commit}, payload) { commit("confirm", payload); },

		alert({commit}, payload) { commit("alert", payload); },

		setOAuthToken({ commit }, token) { commit("setOAuthToken", token); },
		
		logout({commit}) { commit("setOAuthToken", null); },
	},
})
