
/**
 * Created by Durss
 */
export default class Config {

	public static IS_PROD:boolean = /.*\.(com|fr|net|org|ninja|st)$/gi.test(window.location.hostname);
	public static STORAGE_VERSION:number = 1;
	public static TWITCH_CLIENT_ID:string = "8p6rkqbklv5g3hgs8h3itsvfuev086";
	public static TWITCH_SCOPES:string[] = [];
	
	private static _ENV_NAME: EnvName;

	public static init():void {
		let prod = this.IS_PROD;//document.location.port == "";
		if(prod) this._ENV_NAME = "prod";
		else this._ENV_NAME = "dev";
	}
	
	public static get SERVER_PORT(): number {
		return this.getEnvData({
			dev: 3014,
			prod: document.location.port,
		});
	}
	
	public static get SOCKET_PATH():string{
		if(this.IS_PROD) {
			return "/sock";
		}else{
			return window.location.origin.replace(/(.*):[0-9]+/gi, "$1")+":"+this.SERVER_PORT+"/sock";
		}
	};
	
	public static get API_PATH(): string {
		return this.getEnvData({
			dev: document.location.protocol+"//"+document.location.hostname+":"+this.SERVER_PORT+"/api",
			prod:"/api",
		});
	}
	
	public static get PUBLIC_PATH(): string {
		let port = document.location.port? ":"+document.location.port : "";
		return document.location.protocol+"//"+document.location.hostname+port+"/";
	}
	
	public static get UPLOAD_PATH(): string {
		let port = document.location.port? ":"+this.SERVER_PORT : "";
		return document.location.protocol+"//"+document.location.hostname+port+"/uploads/";
	}
	

	/**
	 * Extract a data from an hasmap depending on the current environment.
	 * @param map
	 * @returns {any}
	 */
	private static getEnvData(map: any): any {
		//Get the data from hashmap
		if (map[this._ENV_NAME] !== undefined) return map[this._ENV_NAME];
		return map[Object.keys(map)[0]];
	}
}

type EnvName = "dev" | "preprod" | "prod" | "standalone";