





















import Button from "@/components/Button.vue";
import Utils from "@/utils/Utils";
import MainLoader from "@/views/MainLoader.vue";
import { Component, Vue } from "vue-property-decorator";
import AuthForm from "./AuthForm.vue";
import UserProfile from "./UserProfile.vue";

@Component({
	components:{
		Button,
		AuthForm,
		MainLoader,
		UserProfile,
	}
})
export default class Home extends Vue {

	public get connected():boolean {
		return this.$store.state.OAuthToken;
	}

	public get loading():boolean {
		return this.$store.state.OAuthToken === "";
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public logout():void {
		Utils.confirm("Deconnexion", "Souhaites-tu te déconnecter ?").then(()=>{
			this.$store.dispatch("logout");
		}).catch(()=>{});
	}

}
