















import Api from "@/utils/Api";
import Utils from "@/utils/Utils";
import { TrackData } from "@/views/UserProfile.vue";
import gsap from "gsap/all";
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "./Button.vue";

@Component({
	components:{
		Button,
	}
})
export default class TrackLine extends Vue {

	@Prop()
	public data:TrackData;

	@Prop()
	public username:string;

	public loading:boolean = false;

	public get duration():string {
		const startTime = new Date("01/01/1970 01:"+this.data.timecodeStart).getTime();
		const endTime = new Date("01/01/1970 01:"+this.data.timecodeEnd).getTime();
		return Utils.formatDuration(endTime-startTime).replace(/^(00:)/gi, "");
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public playTrack():void {
		this.$emit("test");
	}

	public editTrack():void {
		this.$emit("edit");
	}

	public async deleteTrack():Promise<void> {
		this.loading = true;
		Utils.confirm("Supprimer ?", "La suppression est irréversible !").then(async _=> {
			try {
				console.log(this.data);
				await Api.delete("private/user/track", {id:this.data._id});
			}catch(error) {
				this.loading = false;
				this.$store.dispatch("alert", "Une erreur est survenue lors de la suppression de du morceau.");
				return;
			}
			this.$emit("delete");
			this.loading = false;
		}).catch(_=>{
			this.loading = false;
		})
	}

	public debugClick():void {
		let json = {
			artist: this.data.artist,
			title: this.data.title,
			expectedAnswers:[this.data.artist.toLowerCase(), this.data.title.toLowerCase()],
			singers: [this.username.toLowerCase()],
			tracks: [this.data._id]
		}
		Utils.copyToClipboard(JSON.stringify(json));
		gsap.fromTo(this.$el, {filter:"brightness(2) saturate(2)", scale:1.1}, {duration:.5, filter:"brightness(1) saturate(1)", scale:1});
	}

}
