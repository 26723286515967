import { render, staticRenderFns } from "./Confirm.vue?vue&type=template&id=01f4df60&scoped=true&"
import script from "./Confirm.vue?vue&type=script&lang=ts&"
export * from "./Confirm.vue?vue&type=script&lang=ts&"
import style0 from "./Confirm.vue?vue&type=style&index=0&id=01f4df60&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f4df60",
  null
  
)

export default component.exports