import SockController from '@/sock/SockController';
import gsap from 'gsap';
import { CSSPlugin, Draggable, InertiaPlugin } from 'gsap/all';
import Vue from 'vue';
import { Route } from 'vue-router';
import App from './App.vue';
import './less/index.less';
import router from './router';
import store from './store';
import Config from './utils/Config';
import Utils from './utils/Utils';

gsap.registerPlugin(Draggable, InertiaPlugin, CSSPlugin);
Vue.config.productionTip = false;
Config.init();
SockController.instance.connect();

router.beforeEach(async (to: Route, from: Route, next: Function) => {
	if (!store.state.ready) {
		let res = await store.dispatch("startApp", { route: to });
	}

	next();
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
