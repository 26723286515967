




















import Button from "@/components/Button.vue";
import Config from "@/utils/Config";
import Utils from "@/utils/Utils";
import { Component, Vue } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class AuthForm extends Vue {

	public loading:boolean = false;

	public get oAuthURL():string {
		let url = "https://discord.com/api/oauth2/authorize";
		url += "?client_id=893599218349006909";
		url += "&redirect_uri="+encodeURIComponent(Config.PUBLIC_PATH+"oauth");
		url += "&response_type=token";
		url += "&state=";
		url += "&scope=identify";
		return url;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public async setLoading():Promise<void> {
		await Utils.promisedTimeout(150);
		this.loading = true;
	}

}
