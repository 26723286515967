







































import AddTrackForm from "@/components/AddTrackForm.vue";
import Button from "@/components/Button.vue";
import TrackLine from "@/components/TrackLine.vue";
import SockController, { SOCK_ACTIONS } from "@/sock/SockController";
import Api from "@/utils/Api";
import SocketEvent from "@/vo/SocketEvent";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CurrentTrack from "./CurrentTrack.vue";

@Component({
	components:{
		Button,
		TrackLine,
		AddTrackForm,
		CurrentTrack,
	}
})
export default class UserProfile extends Vue {

	@Prop()
	public data:UserData;

	public loading:boolean = true;
	public editedTrack:TrackData = null;
	public currentTrack:TrackData = null;
	public localData:UserData = null;

	private sockHandler:any;

	public get username():string {
		return this.$store.state.userLogin;
	}

	public mounted():void {
		if(this.data) {
			this.loading = false;
			this.localData = this.data;
		}else{
			this.loadData();
		}
		this.sockHandler = (e:SocketEvent) => this.onSocketEvent(e);
		SockController.instance.addEventListener(SOCK_ACTIONS.SING, this.sockHandler);
		SockController.instance.addEventListener(SOCK_ACTIONS.STOP, this.sockHandler);
	}

	public beforeDestroy():void {
		SockController.instance.removeEventListener(SOCK_ACTIONS.SING, this.sockHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.STOP, this.sockHandler);
	}

	@Watch("data")
	public onChangeData():void {
		this.localData = this.data;
	}

	public async loadData():Promise<void> {
		this.loading = true;
		let data = await Api.get("private/user");
		this.localData = data.user;
		this.loading = false;
	}

	private onSocketEvent(e:SocketEvent):void {
		switch(e.getType()) {
			case SOCK_ACTIONS.SING:
				let track = this.localData.tracks.filter(v => v._id == e.data.track)[0];
				if(track) {
					this.currentTrack = track;
				}
				break;

			case SOCK_ACTIONS.STOP:
				if(!this.currentTrack) return;
				if(e.data.track == this.currentTrack._id) {
					this.currentTrack = null;
				}
				break;
		}
	}

	public testSong(data:TrackData):void {
		this.currentTrack = null;
		this.currentTrack = data;
	}

	public editTrack(data:TrackData):void {
		this.editedTrack = null;
		this.editedTrack = data;
	}

}
export interface UserData {
	_id:string,
	discordId:string;
	name:string;
	tracks:TrackData[];
}
export interface TrackData {
	_id:string,
	artist:string,
	title:string,
	video:string,
	lyrics:string,
	timecodeStart:string,
	timecodeEnd:string,
}
