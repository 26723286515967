


















import Button from "@/components/Button.vue";
import Api from "@/utils/Api";
import { Component, Vue } from "vue-property-decorator";
import MainLoader from "./MainLoader.vue";
import UserProfile, { UserData } from "./UserProfile.vue";

@Component({
	components:{
		Button,
		MainLoader,
		UserProfile,
	}
})
export default class Admin extends Vue {

	public loading:boolean = true;
	public users:UserData[] = [];
	public currentUser:UserData = null;

	public async mounted():Promise<void> {
		try {
			let res = await Api.get("private/user/isadmin");
			if(!res.isAdmin) {
				this.$router.push({name:"home"});
				return;
			}
		}catch(error) {
			this.$store.dispatch("alert", "La récupération du status \"admin\" a échoué :(");
			this.$router.push({name:"home"});
			return;
		}
		
		this.populate();
	}

	public beforeDestroy():void {
		
	}
	
	public async populate():Promise<void> {
		try {
			let res = await Api.get("private/admin/userlist");
			this.users = res.users
		}catch(error) {
			this.$store.dispatch("alert", "La récupération des utilisateurices a échoué :(");
		}
		this.loading = false;
	}

}
