









































import Api from "@/utils/Api";
import { TrackData } from "@/views/UserProfile.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Button from "./Button.vue";

@Component({
	components:{
		Button,
	}
})
export default class AddTrackForm extends Vue {

	@Prop()
	public editedTrack:TrackData;

	public title:string = "";
	public artist:string = "";
	public video:string = "";
	public lyrics:string = "";
	public loading:boolean = false;
	public videoValid:boolean = true;
	public timecodeStart:string = "00:00"
	public timecodeEnd:string = "00:30";

	public get canSubmit():boolean {
		return this.title.length > 0 && this.artist.length > 0 && this.video.length > 0;
	}

	public get videoClasses():string[] {
		let res = ["row"]
		if(!this.videoValid) res.push("error");
		return res;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		

	}

	public async onSubmit():Promise<void> {
		if(!this.canSubmit) return;
		
		let startTime = new Date("01/01/1970 01:"+this.timecodeStart).getTime();
		let endTime = new Date("01/01/1970 01:"+this.timecodeEnd).getTime();
		if(endTime < startTime) {
			let tmp = this.timecodeEnd;
			this.timecodeEnd = this.timecodeStart;
			this.timecodeStart = tmp;
		}

		let form:any = {};
		form["title"] = this.title;
		form["artist"] = this.artist;
		form["video"] = this.video;
		form["timecodeStart"] = this.timecodeStart;
		form["timecodeEnd"] = this.timecodeEnd;
		form["lyrics"] = this.lyrics;

		this.loading = true;
		let error = false;
		try {
			if(this.editedTrack) {
				form["id"] = this.editedTrack._id;
				await Api.put("private/user/track", form);
			}else{
				await Api.post("private/user/track", form);
			}
		}catch(error) {
			this.$store.dispatch("alert", "Une erreur est survenue lors de l'ajout du morceau :(");
			error = true;
		}
		this.$emit("update");
		this.loading = false;
		if(!error) {
			this.resetForm();
		}
	}

	public async checkVideo():Promise<void> {
		this.videoValid = true;
		const matches = this.video.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/);
		let id = matches?.length>0? matches[1] : null;
		if(!id) return;
		let res = await Api.get("private/video/isvalid", {id});
		this.videoValid = res?.infos?.status?.embeddable === true;
	}

	/**
	 * Edits a track
	 */
	@Watch("editedTrack")
	public onEditTrack():void {
		if(this.editedTrack) {
			this.title = this.editedTrack.title;
			this.artist = this.editedTrack.artist;
			this.video = this.editedTrack.video;
			this.timecodeStart = this.editedTrack.timecodeStart;
			this.timecodeEnd = this.editedTrack.timecodeEnd;
			this.lyrics = this.editedTrack.lyrics;
		}
	}

	public resetForm():void {
		this.title = "";
		this.artist = "";
		this.video = "";
		this.lyrics = "";
		this.loading = false;
		this.videoValid = true;
		this.timecodeStart = "00:00"
		this.timecodeEnd = "00:30";
		this.$emit("cancelEdit");
	}

}
